import { ApolloClient, HttpLink, InMemoryCache, ApolloLink } from '@apollo/client/core'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { print } from '@apollo/client/utilities'
import { sha256 } from 'crypto-hash'

const persistedQueriesLink = createPersistedQueryLink({
  generateHash(query) {
    return Promise.resolve<string>(
      sha256!(print(query) + `${import.meta.env.VITE_GD_ORG_ID}_${import.meta.env.VITE_WEBSITE_ID}`)
    )
  },
  useGETForHashedQueries: true
})

// HTTP connection to the API
const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: (import.meta.env.VITE_GD_API_GRAPHQL_URL ?? 'https://api.gorilladash.com') + '/graphql',
  headers: {
    Authorization: `Bearer ${window.graphqlToken}`
  }
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: import.meta.env.PROD
    ? persistedQueriesLink.concat(ApolloLink.from([httpLink]))
    : ApolloLink.from([httpLink]),
  cache
})

if (import.meta.env.DEV) {
  loadDevMessages()
  loadErrorMessages()
}

export { apolloClient }
